import React from "react";
import { AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import { AddressVerification } from "types/api/addressVerification";

interface Props {
  addressVerifcation?: AddressVerification;
}

function AddressMap(props: Props) {
  const id = React.useId();
  const addresses =
    (props.addressVerifcation?.addresses || []).filter(
      (addr) => addr.latitude && addr.longitude,
    ) || [];

  const address = addresses[0];
  const position = {
    lat: address?.latitude as number,
    lng: address?.longitude as number,
  };

  if (addresses.length === 0) {
    return null;
  }

  return (
    <div className="h-[500px]">
      <Map defaultCenter={position} defaultZoom={6} mapId={id}>
        {addresses.map((address, index) => (
          <AdvancedMarker
            key={index}
            position={{
              lat: address.latitude,
              lng: address.longitude,
            }}
          />
        ))}
      </Map>
    </div>
  );
}

export default AddressMap;
