import React, { useEffect, useRef } from "react";
import useUrlState from "@ahooksjs/use-url-state";
import { ActionType, ProColumns, ProTable } from "@ant-design/pro-components";
import { useSessionStorageState } from "ahooks";
import { AutoComplete, Button, SelectProps } from "antd";
import type { SortOrder } from "antd/es/table/interface";
import { omit, omitBy, sortBy } from "lodash";

import { getEnvironment } from "contexts/displayEnvironment";
import { getResource, usePartnerIds } from "queries";
import type { AccessLog } from "types/accessLogs";
import { getJobTypes } from "util/selectors";

interface TableParams {
  partner: string;
  query: string;
  job_type: string;
  startDate: string;
  endDate: string;
}

const AccessLogs: React.FC = () => {
  const envMap = { true: "production", false: "sandbox" };
  const [env] = useSessionStorageState("displayEnvironment", {
    listenStorageChange: true,
  });
  const displayEnvironment = envMap[env as keyof typeof envMap];
  const { data: partnerIdsData } = usePartnerIds();

  const [_filters, setFilters] = useUrlState({
    query: "",
    partner: "",
    startDate: "",
    endDate: "",
    job_type: "",
  });

  const [pagination, setPagination] = useUrlState({
    current: "1",
    pageSize: "20",
  });
  const actionRef = useRef<ActionType>();

  useEffect(() => {
    actionRef.current?.reload();
  }, [displayEnvironment]);

  const columns: ProColumns<AccessLog>[] = [
    {
      dataIndex: "created_at",
      key: "createdAtDisplay",
      title: "Date",
      valueType: "dateTime",
      hideInSearch: true,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      valueType: "dateRange",
      hideInTable: true,
      search: {
        transform: (value) => ({
          startDate: value[0],
          endDate: value[1],
        }),
      },
    },
    {
      dataIndex: "partner_id",
      key: "partner",
      title: "Partner",
      valueType: "text",
      renderFormItem: () => (
        <AutoComplete
          options={partnerIdsData?.partnerIds.map((partner) => ({
            label: partner,
            value: partner.split(" - ")[0],
          }))}
          filterOption={(inputValue, option) =>
            option!.label
              .toLowerCase()
              .includes(inputValue.trim().toLowerCase())
          }
          placeholder="Enter partner ID/name"
        />
      ),
    },
    {
      dataIndex: "job_id",
      key: "query",
      title: "Job ID",
      fieldProps: { maxLength: 10, placeholder: "Enter Smile job ID" },
    },
    {
      dataIndex: "job_type",
      title: "Product",
      valueType: "select",
      valueEnum: Object.fromEntries([
        ...getJobTypes().map(({ value, label }) => [value, { text: label }]),
        ["jt_5", { text: "Basic/Enhanced KYC" }],
      ]),
      fieldProps: {
        placeholder: "Select product",
        popupMatchSelectWidth: false,
        showSearch: true,
        optionFilterProp: "label",
        options: sortBy(getJobTypes(), "label"),
      } as SelectProps,
    },
    {
      dataIndex: "result_code",
      title: "Result Code",
      hideInSearch: true,
    },
    {
      dataIndex: "result_message",
      title: "Result Text",
      hideInSearch: true,
      className: "max-w-[12rem]",
    },
    {
      dataIndex: "amount",
      title: "Charges",
      valueType: "money",
      hideInSearch: true,
      renderText: (text: string | null) =>
        text
          ? new Intl.NumberFormat("en-US", {
              currency: "USD",
              style: "currency",
            }).format(Math.abs(Number(text)))
          : null,
    },
    {
      dataIndex: "sdk",
      title: "SDK",
      hideInSearch: true,
    },
    {
      title: "Actions",
      hideInSearch: true,
      render: (_, record) => (
        <Button
          type="link"
          href={`/admin/job/${getEnvironment()}/${record.id}`}
          target="_blank"
          rel="noreferrer"
        >
          View
        </Button>
      ),
    },
  ];

  const handleRequest = async (
    params: TableParams & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
    sort: Record<string, SortOrder>,
    filter: Record<string, (string | number)[] | null>,
  ) => {
    const filters = omit({ ...params, ...filter }, ["current", "pageSize"]);
    const res = await getResource<AccessLog>({
      path: "/access_logs",
      displayEnvironment: displayEnvironment!,
      filters,
      sort: sort as unknown as Record<string, "ascending" | "descending">,
      pagination: {
        page: params.current,
        pageSize: params.pageSize,
      },
    });

    return {
      data: res.data.map((log) => ({
        ...log,
        job_type: `jt_${log.ran_as_job_type}`,
      })),
      success: true,
      total: res.totalRecords,
    };
  };
  const handleFilterSubmit = async (params: TableParams) => {
    setFilters({
      query: params.query || undefined,
      partner: params.partner || undefined,
      startDate: params.startDate || undefined,
      endDate: params.endDate || undefined,
      job_type: params.job_type || undefined,
    });
  };

  const syncFormToUrl = (
    values: Record<string, string>,
    type: "get" | "set",
  ) => {
    if (type === "get") {
      return {
        ...values,
        created_at: [values.startDate, values.endDate],
      };
    }

    return omitBy(values, (v) => v == null || v === "");
  };

  const handleFilterReset = () => {
    actionRef.current?.reset?.();
    setFilters({
      query: undefined,
      partner: undefined,
      startDate: undefined,
      endDate: undefined,
      job_type: undefined,
    });
  };

  return (
    <ProTable<AccessLog, TableParams>
      columns={columns}
      actionRef={actionRef}
      cardBordered
      request={handleRequest}
      columnsState={{
        persistenceKey: "admin:access-logs",
        persistenceType: "localStorage",
      }}
      rowKey="id"
      search={{
        labelWidth: "auto",
        layout: "vertical",
        collapsed: false,
        collapseRender: () => null,
        optionRender: () => [
          <Button onClick={handleFilterReset}>Clear Filters</Button>,
          <Button htmlType="submit" type="primary">
            Apply
          </Button>,
        ],
      }}
      onSubmit={handleFilterSubmit}
      form={{
        syncToInitialValues: false,
        syncToUrl: syncFormToUrl,
      }}
      pagination={{
        "aria-label": "Pagination",
        pageSize: Number(pagination.pageSize),
        current: Number(pagination.current),
        hideOnSinglePage: true,
        onChange: (current, pageSize) => {
          setPagination({ current, pageSize });
        },
        showSizeChanger: true,
        showQuickJumper: true,
      }}
      dateFormatter="string"
      headerTitle="Access Logs"
    />
  );
};

export default AccessLogs;
