import React, { useContext, useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { format, parseISO } from "date-fns";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router-dom";
import NewsArticlesShimmer from "components/reusable/shimmers/NewsArticlesShimmer";
import BackButton from "components/reusable/web_links/BackButton";
import { StyledButton } from "components/reusable/web_links/StyledButton";
import { AmlNewsContext } from "contexts/AmlNewsContext";
import { fetchNewsArticles } from "util/api_util";
import styles from "./news_media.module.css";
import { AMLNewsMedia } from "./NewsMedia";

export interface AmlNewsArticle {
  body: string;
  human_verified: boolean;
  published_date: string;
  title: string;
  url: string;
}

export interface NewsArticlesProps {
  post: AMLNewsMedia;
  onClose: () => void;
  fetchArticles?: typeof fetchNewsArticles;
}

interface NewsErrorResponse {
  error: string;
  code: string;
}

export interface NewsSuccessResponse {
  news: AmlNewsArticle[];
  meta: { currentPage: number; limit: number; totalCount: number };
}

interface NewsResponse extends NewsSuccessResponse, NewsErrorResponse {}

interface NewsArticleParams {
  partnerId: string;
}

const NewsArticles: React.FC<NewsArticlesProps> = ({
  post,
  onClose,
  fetchArticles = fetchNewsArticles,
}) => {
  const navRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { inView, ref } = useInView();
  const { selectedPerson, userId, smileJobId } = useContext(AmlNewsContext);
  const { partnerId } = useParams<NewsArticleParams>();
  const [showScrollButton, setShowScrollButton] = useState(false);

  const payload = {
    user_id: userId,
    job_id: smileJobId,
    ref: selectedPerson?.ref,
    partner_id: partnerId,
    news_category: post.newsCategory,
  };

  const getNews = async ({
    pageParam,
  }: {
    pageParam: unknown;
  }): Promise<NewsResponse> => {
    const response = (await fetchArticles({
      ...payload,
      page: pageParam,
    })) as unknown as NewsResponse;

    if (response.error) {
      throw new Error("Could not load news articles, please try again");
    }
    return response;
  };

  const scrollToTop = (): void => {
    navRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const toggleScrollButtonVisibility = (): void => {
    const containerElement = containerRef.current?.parentElement;
    if (!containerElement?.scrollTop) {
      return;
    }

    if (containerElement.scrollTop > 200) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    const containerElement = containerRef.current;
    containerElement?.parentElement?.addEventListener(
      "scroll",
      toggleScrollButtonVisibility,
    );

    return () => {
      containerElement?.parentElement?.removeEventListener(
        "scroll",
        toggleScrollButtonVisibility,
      );
    };
  }, []);

  const {
    data: articles,
    error,
    isFetchingNextPage,
    isFetching,
    refetch,
    fetchNextPage: fetchNext,
  } = useInfiniteQuery({
    queryKey: ["news", post.newsCategory],
    retry: false,
    queryFn: getNews,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.news.length === 0) {
        return null;
      }
      const nextPage = lastPage.meta.currentPage + 1;

      return nextPage;
    },
    refetchOnWindowFocus: false,
  });

  const lastArticle = articles?.pages?.[articles.pages.length - 1];

  useEffect(() => {
    if (inView && !isFetchingNextPage && !isFetching && !error) {
      fetchNext();
    }
  }, [inView, isFetchingNextPage, isFetching, error]);

  return (
    <div className={styles.container} ref={containerRef}>
      <div className={classNames(styles.navigation)} ref={navRef}>
        <BackButton
          style={{ flexFlow: "nowrap" }}
          onClick={onClose}
          className={styles.backButton}
          label="Back"
        />{" "}
        {lastArticle && (
          <div className={styles.articleCount}>
            {lastArticle.meta?.totalCount} News Article(s)
          </div>
        )}
      </div>
      <div>
        <h2 className={classNames("p", styles.articleHeader)}>
          News Article Related to {post.newsCategoryLabel}
        </h2>
      </div>
      {isFetching && !articles && <NewsArticlesShimmer />}
      {error && (
        <div>
          <div>{error.message}</div>
          <StyledButton onClick={() => refetch()}>Retry</StyledButton>
        </div>
      )}

      {articles && (
        <div className={classNames(styles.articles)} ref={ref}>
          {articles.pages?.map((page, i) => (
            <React.Fragment key={`article-${i}`}>
              {page.news.map((article: AmlNewsArticle) => (
                <NewsArticle key={article.url} article={article} />
              ))}
            </React.Fragment>
          ))}
          <div>{isFetchingNextPage && "Loading More"}</div>
        </div>
      )}

      {articles && showScrollButton && (
        <StyledButton className={styles.scrollToTop} onClick={scrollToTop}>
          Back to top
        </StyledButton>
      )}
    </div>
  );
};

interface NewsArticleProps {
  article: AmlNewsArticle;
}

export const NewsArticle: React.FC<NewsArticleProps> = ({ article }) => (
  <div className={styles.article}>
    <div className={classNames(styles.articleDate, "p")}>
      <div>{format(parseISO(article.published_date), "d MMMM yyyy")}</div>{" "}
      <div>
        {article.human_verified ? "Human Verified" : "Not Human Verified"}
      </div>
    </div>
    <div
      className={classNames(styles.articleTitle, "font-medium leading-normal")}
    >
      <a href={article.url} target="_blank" rel="noopener noreferrer">
        {article.title}
      </a>
    </div>
    <div
      className={classNames(
        styles.articleBody,
        "font-normal text-sm leading-normal",
      )}
    >
      {article.body}
    </div>
  </div>
);

export default NewsArticles;
