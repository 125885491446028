import React, { useState } from "react";

import moment from "moment";
import PageHeader from "components/page_header/PageHeader";
import PrintPdfButton from "components/reusable/print_pdf_button";
import Tabs, { Tab } from "components/tabs";
import { AmlNewsContext } from "contexts/AmlNewsContext";
import fileTextIcon from "resources/img/icons/file-text.svg";
import newsPaperIcon from "resources/img/icons/newspaper.svg";
import personCircleIcon from "resources/img/icons/person-circle.svg";
import protectionIcon from "resources/img/icons/protection.svg";
import twoPeopleIcon from "resources/img/icons/two-people.svg";

import AmlPersonFound from "./aml-person-found/aml_person_found";
import styles from "./aml_results.module.css";
import AmlSummaryCards from "./aml_summary_cards";
import AmlTable from "./aml_table";
import tableStyles from "./aml_table.module.css";
import AmlIndicator from "./AmlIndicators";
import AmlMonitoringCard from "./AmlMonitoringCard";

function AmlResults({ jobResult, showMonitoringDetails = false }) {
  const {
    result,
    smile_reference_created_at: searchDate,
    match_name: fullname,
    countries,
    birth_year: birthYear,
    pii_data_expired,
    monitored,
  } = jobResult;

  const searchTerms = {
    fullname,
    birthYear,
    countries,
    strictMatch: result.StrictMatch ? "Yes" : "No",
  };

  const [personDetailsDrawer, setPersonDetailsDrawer] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState({});
  const [activeTab, setActiveTab] = useState("reports");
  const {
    people,
    updates,
    PartnerParams: { user_id: userId },
    SmileJobID: smileJobId,
  } = result;

  const PEP_HIGH_RISK = 1;
  const PEP_MEDIUM_RISK = 2;
  const PEP_LOW_RISK = 3;
  const PEP_FORMER_PEP = "Former PEP";

  const getSummary = (people) => {
    const summary = {
      sanctions: 0,
      enforcementActions: 0,
      peps: 0,
      associations: 0,
      adverseMedia: 0,
      newsMedia: 0,
      pepHigh: 0,
      pepMedium: 0,
      pepLow: 0,
      pepFormer: 0,
    };
    const pepLevels = {
      [PEP_HIGH_RISK]: 0,
      [PEP_MEDIUM_RISK]: 0,
      [PEP_LOW_RISK]: 0,
      [PEP_FORMER_PEP]: 0,
    };

    people?.forEach((person) => {
      summary.sanctions += person.sanctions?.length || 0;
      summary.peps += person.pep?.political_positions?.length || 0;
      summary.enforcementActions += person.enforcement_actions?.length || 0;
      summary.associations += person.associations?.length || 0;
      summary.adverseMedia += person.adverse_media?.length || 0;
      summary.newsMedia += person.news_summary?.length || 0;

      if (person.pep.pep_level) {
        pepLevels[person.pep.pep_level] += 1;
      }
    });

    summary.pepHigh = pepLevels[PEP_HIGH_RISK];
    summary.pepMedium = pepLevels[PEP_MEDIUM_RISK];
    summary.pepLow = pepLevels[PEP_LOW_RISK];
    summary.pepFormer = pepLevels[PEP_FORMER_PEP];

    return summary;
  };

  const summary = getSummary(people);

  const status =
    result.no_of_persons_found > 0 ? result.Actions?.Listed : "Clear";
  const getStatusClass = () => {
    if (status === "Clear") return styles.amlCardStatusClear;
    if (status === "Listed") return styles.amlCardStatusListed;
    return styles.amlCardStatusWarning;
  };

  const columns = [
    {
      name: "row_number",
    },
    {
      name: "reported_full_name",
      label: "Reported Full Name",
    },
    {
      name: "date_of_birth",
      label: "Date of Birth",
    },
    {
      name: "nationalities",
      label: "Nationalities",
    },
    {
      name: "indicators",
      label: "Indicators",
    },
  ];

  const selectPerson = (person) => {
    setPersonDetailsDrawer(true);
    setSelectedPerson(person);
  };

  const unselectPerson = () => {
    setPersonDetailsDrawer(false);
    setSelectedPerson({});
  };

  const newsIndicator = (newsSummary = []) => {
    if (!newsSummary) {
      return 0;
    }
    const totalNews = newsSummary.length;
    return Intl.NumberFormat().format(totalNews);
  };

  const data = people?.map((person, index) => ({
    row_number: <div className={styles.rowNumber}>{index + 1}. </div>,
    reported_full_name: (
      <div className={styles.amlTableCell}>
        <h3 className="text-sm font-medium">{person.name}</h3>

        <span>Alias(es): </span>
        {person.aliases.length > 0 && <span>{person.aliases[0]}</span>}
        {person.aliases.length > 1 && <span> ...</span>}
      </div>
    ),
    date_of_birth: (
      <div className="text-sm font-medium">
        <span>
          {person.dates_of_birth.length > 0
            ? moment(person.dates_of_birth[0]).format("YYYY")
            : "N/A"}
        </span>
        {person.dates_of_birth.length > 1 && <span>...</span>}
      </div>
    ),
    nationalities: (
      <div className="text-sm font-medium">
        <span>
          {person.nationalities?.length > 0 ? person?.nationalities[0] : "N/A"}
        </span>
        {person.nationalities?.length > 1 && <span>...</span>}
      </div>
    ),
    indicators: (
      <div className={styles.amlTableIndicators}>
        <AmlIndicator count={person.sanctions?.length} label="Sanctions" />
        <AmlIndicator
          count={person.enforcement_actions?.length}
          label="Enforcement"
        />
        <AmlIndicator
          count={person.pep?.political_positions?.length}
          label="PEP"
        />
        <AmlIndicator
          count={person.associations?.length}
          label="Associations"
        />
        <AmlIndicator
          count={person.adverse_media?.length}
          label="Adverse Media"
        />
        <AmlIndicator
          count={newsIndicator(person?.news_summary)}
          label="News Media"
        />
      </div>
    ),
  }));

  if (pii_data_expired) {
    return (
      <div className={styles.container}>
        <h1>Watchlist Summary</h1>
        <section className={styles.searchDetailsContainer}>
          <div className={styles.searchDetails}>
            <div>
              <h5>Search Date</h5>
              <span className={styles.offblack}>
                {moment(searchDate).format("DD MMM, YYYY")}
              </span>
            </div>
            <div>
              <h5>Job ID</h5>
              <span className={styles.offblack}>{smileJobId}</span>
            </div>
            <div>
              <h5> User ID </h5>
              <span className={styles.offblack}>{userId}</span>
            </div>
          </div>
        </section>
        <div>
          <h3>Job is outside data retention period</h3>
        </div>
      </div>
    );
  }

  /**
   * AmlUpdates
   */
  const updateColumns = [
    { name: "type", label: "Type" },
    { name: "indicator", label: "Indicator" },
    { name: "details", label: "Details" },
    { name: "person", label: "Person" },
  ];
  const getUpdateChangeType = (type) => {
    switch (type) {
      case "addition":
        return (
          <p className={styles.tag} data-variant="added">
            Added
          </p>
        );
      case "update":
        return (
          <p className={styles.tag} data-variant="updated">
            Updated
          </p>
        );
      case "deletion":
        return (
          <p className={styles.tag} data-variant="deleted">
            Deleted
          </p>
        );
      default:
        throw new Error("unsupported aml update changeType");
    }
  };
  const getIndicatorForUpdateType = (update) => {
    switch (update.type) {
      case "Adverse Media":
        return (
          <div>
            <img alt="" src={newsPaperIcon} />
            <p>{update.type}</p>
          </div>
        );
      case "Association":
        return (
          <div>
            <img alt="" src={twoPeopleIcon} />
            <p>{update.type}</p>
          </div>
        );
      case "Enforcement Action":
        return (
          <div>
            <img alt="" src={protectionIcon} />
            <p>{update.type}</p>
          </div>
        );
      case "PEP":
        return (
          <div>
            <img alt="" src={personCircleIcon} />
            <p>{update.type}</p>
          </div>
        );
      case "Sanction":
        return (
          <div>
            <img alt="" src={fileTextIcon} />
            <p>{update.type}</p>
          </div>
        );
      default:
        throw new Error("unsupported aml update type");
    }
  };

  const getDetailsForUpdateType = (update) => {
    switch (update.type) {
      case "Adverse Media":
        return (
          <div>
            <h3>{update.title}</h3>
            <p>
              {moment(update.date_published).format("DD MMM, YYYY")} |{" "}
              {moment(update.date_published).format("HH:mm")}
            </p>
            <p>
              Source:{" "}
              <a target="_blank" rel="noreferrer" href={update.source_link}>
                {update.publisher}
              </a>
            </p>
          </div>
        );
      case "Association":
        return (
          <div>
            <h3>{update.relationship}</h3>
            <p>who has {update.association_type} indicators</p>
          </div>
        );
      case "Enforcement Action":
        return (
          <div>
            <h3>{update.description}</h3>
            {update.source_details.length > 0 && (
              <p>
                Sources:{" "}
                {update.source_details.map((source, index) => (
                  <span key={`ea-source-${index}`}>
                    {index ? ", " : ""}
                    <a href={source.source_link}>{source.source_name}</a>
                  </span>
                ))}
              </p>
            )}
          </div>
        );
      case "PEP":
        return (
          <div>
            <h3>
              is a {update.pep_level.includes("Former") ? "" : "Level "}{" "}
              {update.pep_level}
              {update.pep_level.includes("Former") ? "" : " PEP"}
            </h3>
            <p>
              {" "}
              who {update.pep_level.includes("Former")
                ? "held"
                : "holds/held"}{" "}
              {update.political_positions.length} positions
            </p>
          </div>
        );
      case "Sanction":
        return (
          <div>
            <h3>{update.source_details.source_name}</h3>
            {update.source_details.listed_date && (
              <p>
                {" "}
                on{" "}
                {moment(update.source_details.listed_date).format(
                  "DD MMM, YYYY",
                )}
              </p>
            )}
            {update.source_details.source_link && (
              <p>
                <a href={update.source_details.source_link}>
                  {update.source_details.source_name}
                </a>
              </p>
            )}
          </div>
        );
      default:
        throw new Error("unsupported aml update type");
    }
  };
  const getPersonForUpdate = (update) => {
    const person =
      people.find((person) => person.ref === update.ref) || people[0];

    const name = person ? person.name : "N/A";

    return (
      <div className={styles.amlTableCell}>
        <h3>{name}</h3>
      </div>
    );
  };
  const updateData = updates?.map((update) => ({
    type: (
      <div>
        {getUpdateChangeType(update.changeType)}
        <p>{moment(update.date).format("DD MMM, YYYY")}</p>
      </div>
    ),
    indicator: getIndicatorForUpdateType(update),
    details: getDetailsForUpdateType(update),
    person: getPersonForUpdate(update),
  }));

  return (
    <div className={styles.container}>
      <section className={styles.header}>
        <PageHeader titleClassName={styles.title} title="Watchlist Summary" />
        <PrintPdfButton resultPayload={jobResult} />
      </section>

      <div onClick={() => unselectPerson()}>
        <section className={styles.searchDetailsContainer}>
          <div className={styles.searchDetails}>
            <div>
              <h5>Search Date</h5>
              <span className={styles.offblack}>
                {moment(searchDate).format("DD MMM, YYYY")}
              </span>
            </div>
            <div>
              <h5>Job ID</h5>
              <span className={styles.offblack}>{smileJobId}</span>
            </div>
            <div>
              <h5> User ID </h5>
              <span className={styles.offblack}>{userId}</span>
            </div>
          </div>
        </section>

        <div>
          <section className="text-base">
            <h4 className={styles.sectionTitle}>Search Results for:</h4>
            <table>
              <thead>
                <tr className={styles.border}>
                  <th className={styles.fullname}>Full Name</th>
                  <th>Year of Birth</th>
                  <th className={styles.nationality}>Nationalities</th>
                  <th>Strict Match</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr className={styles.border}>
                  <td>{searchTerms?.fullname}</td>
                  <td>{searchTerms?.birthYear}</td>
                  <td>{searchTerms?.countries?.join(",")}</td>
                  <td>{searchTerms?.strictMatch}</td>
                  <td>
                    <span className={getStatusClass()}>{status}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          {showMonitoringDetails && (
            <section className={styles.amlMonitoring}>
              <AmlMonitoringCard
                monitored={monitored}
                noOfUpdates={updates.length}
              />
            </section>
          )}
        </div>

        <section>
          <AmlSummaryCards summary={summary} />
        </section>
      </div>

      {personDetailsDrawer && (
        <AmlNewsContext.Provider value={{ selectedPerson, userId, smileJobId }}>
          <div className={styles.amlPersonFoundDrawer}>
            <AmlPersonFound
              person={selectedPerson}
              onClose={() => unselectPerson()}
            />
          </div>
        </AmlNewsContext.Provider>
      )}

      <Tabs
        variant="large"
        activeTab={activeTab}
        onTabChange={(tab) => setActiveTab(tab)}
      >
        <Tab id="reports" title="Reports">
          {people?.length == 0 && (
            <section className={styles.amlTableContainer}>
              <h4 className={styles.sectionTitle}>No Persons Found </h4>
            </section>
          )}
          {people?.length > 0 && (
            <section className={styles.amlTableContainer}>
              <h4 className={styles.sectionTitle}>Persons Found </h4>
              <AmlTable
                data={data}
                columns={columns}
                selectedIndex={people.indexOf(selectedPerson)}
                activeClassName={tableStyles.selected}
                tableClassName={tableStyles.table}
                onRowClick={(index) => selectPerson(people[index])}
              />
            </section>
          )}
        </Tab>

        {showMonitoringDetails && updates?.length > 0 && (
          <Tab id="history" title="History">
            <section className={styles.amlTableContainer}>
              <AmlTable
                data={updateData}
                columns={updateColumns}
                tableClassName={tableStyles.table}
              />
            </section>
          </Tab>
        )}
      </Tabs>
    </div>
  );
}

export default AmlResults;
